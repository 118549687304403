export const generateMeta = (seo, title, usestencilImageUrl = null) => {
  if (!seo) return []

  const meta = []

  if (seo.description) {
    meta.push({
      hid: 'description',
      name: 'description',
      content: formatText(seo.description),
    })
  }

  if (Array.isArray(seo.robots) && seo.robots.length) {
    meta.push({
      hid: 'robots',
      name: 'robots',
      content: seo.robots.map(({ value }) => value).join(', '),
    })
  }

  meta.push({
    hid: 'og:type',
    name: 'og:type',
    content: 'website',
  })

  if (seo.og_title) {
    meta.push({
      hid: 'og:title',
      property: 'og:title',
      content: seo.og_title,
    })
  }

  if (seo.description) {
    meta.push({
      hid: 'og:description',
      property: 'og:description',
      content: formatText(seo.description),
    })
  }

  meta.push({
    hid: 'og:url',
    property: 'og:url',
    content: seo.canonical_url,
  })

  if (seo.site_name) {
    meta.push({
      hid: 'og:site_name',
      property: 'og:site_name',
      content: seo.site_name,
    })
  }

  meta.push({
    hid: 'og:locale',
    property: 'og:locale',
    content: seo.site?.locale,
  })

  if (seo.alternate_locales) {
    seo.alternate_locales.forEach((locale) => {
      meta.push({
        hid: 'og:locale:alternate',
        property: 'og:locale:alternate',
        content: locale.site?.locale,
      })
    })
  }

  meta.push({
    hid: 'twitter:card',
    name: 'twitter:card',
    content: seo.twitter_card,
  })

  if (seo.twitter_handle) {
    meta.push({
      hid: 'twitter:site',
      name: 'twitter:site',
      content: seo.twitter_handle,
    })
  }

  if (title) {
    meta.push({
      hid: 'twitter:title',
      name: 'twitter:title',
      content: title,
    })
  }

  if (seo.description) {
    meta.push({
      hid: 'twitter:description',
      name: 'twitter:description',
      content: formatText(seo.description),
    })
  }

  if (usestencilImageUrl) {
    meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: usestencilImageUrl,
    })
    meta.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: usestencilImageUrl,
    })
  } else if (seo.image) {
    meta.push({
      hid: 'og:image',
      property: 'og:image',
      content: seo.image.permalink,
    })
    meta.push({
      hid: 'og:image:width',
      property: 'og:image:width',
      content: seo.image.width,
    })
    meta.push({
      hid: 'og:image:height',
      property: 'og:image:height',
      content: seo.image.height,
    })
    meta.push({
      hid: 'twitter:image',
      name: 'twitter:image',
      content: seo.image.permalink,
    })
    meta.push({
      hid: 'twitter:image:alt',
      name: 'twitter:image:alt',
      content: seo.image.alt,
    })
  }

  if (seo.google_verification) {
    meta.push({
      hid: 'google-site-verification',
      name: 'google-site-verification',
      content: seo.google_verification,
    })
  }

  if (seo.bing_verification) {
    meta.push({
      hid: 'msvalidate.01',
      name: 'msvalidate.01',
      content: seo.bing_verification,
    })
  }

  return meta
}

export const generateLinks = (seo) => {
  if (!seo) return []

  const links = []

  links.push({
    rel: 'home',
    href: seo.home_url,
  })

  links.push({
    rel: 'canonical',
    href: seo.canonical_url,
  })

  if (seo.prev_url) {
    links.push({
      rel: 'prev',
      href: seo.prev_url,
    })
  }

  if (seo.next_url) {
    links.push({
      rel: 'next',
      href: seo.next_url,
    })
  }

  if (seo.alternate_locales) {
    seo.alternate_locales.forEach((locale) => {
      links.push({
        rel: 'alternate',
        href: locale.url,
        hreflang: locale.site?.locale,
      })
    })
  }

  if (seo.humans_txt) {
    links.push({
      type: 'text/plain',
      rel: 'author',
      href: seo.humans_txt,
    })
  }

  return links
}

// ------ HELPERS ------

export const removeHtmlTags = (text) => {
  if (!text) return ''

  // replace <br> and </p><p> with space and remove all other html tags
  return text.replace(/<br>|<\/p><p>/gi, ' ').replace(/<[^>]*>/g, '')
}

// Title: max 70 chars, description: max 160 chars
export const formatText = (text, length = 160) => {
  if (!text) return ''

  text = text.trim()
  text = removeHtmlTags(text)
  if (text.length > length) {
    text = text.slice(0, length) + '...'
  }
  return text
}

export const generateUsestencilImageUrl = (page, store, locale) => {
  const config = useRuntimeConfig()
  let date = ''
  let title = page.title

  // NEWS ARTICLE
  if (page.blueprint === 'news') {
    const publishedAt = new Date(page.published_at)
    let date = publishedAt.toLocaleDateString(page.locale, {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })

    title = encodeURIComponent(title)
    date = encodeURIComponent(date)

    if (page.news_image) {
      const image = encodeURIComponent(page.news_image)
      return `${config.public.USESTENCIL_NEWS_WITH_IMAGE_URL}?title=${title}&date=${date}&image=${image}`
    }
    return `${config.public.USESTENCIL_NEWS_URL}?title=${title}&date=${date}`
  }

  // JOB
  if (page.blueprint === 'jobs') {
    if (page.from_now) {
      date = store?.translations?.start_from_now
    } else if (page.start) {
      date = page.start
    }

    if (!date) {
      date = page.in_join_created_at
    }

    if (page.pensum) {
      title = `${page.title} ${page.pensum}`
    }

    // use space to override default value from usestencil
    title = encodeURIComponent(title)
    date = encodeURIComponent(date || ' ')
    const companyName = encodeURIComponent(page.company_name || ' ')
    const location = encodeURIComponent(page.location || ' ')
    return `${config.public.USESTENCIL_JOB_URL}?title=${title}&date=${date}&company=${companyName}&location=${location}`
  }

  // EVENT
  if (page.blueprint === 'event') {
    title = encodeURIComponent(title)
    const image = encodeURIComponent(page.image?.permalink || '')

    let date = new Date(page.date).toLocaleDateString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })

    date = encodeURIComponent(date)

    const organizer = encodeURIComponent(page.event_organizer?.title)
    const type = encodeURIComponent(page.event_type?.title || '')

    const time = `${page.time}-${page.time_until} ${store?.translations?.hrs}`

    let location = page.location || ''
    if (location && page.online) {
      location += page.online ? ` & ${store?.translations?.event_online}` : ''
    }
    if (!location && page.online) {
      location = store?.translations?.event_online
    }

    location = encodeURIComponent(`${location}, ${time}`)

    return `${config.public.USESTENCIL_EVENT_URL}?title=${title}&date=${date}&organizer=${organizer}&type=${type}&location=${location}&image=${image}`
  }
}
