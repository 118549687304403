<template>
  <div class="flex min-h-screen w-full flex-col justify-between">
    <template v-if="page">
      <div>
        <Navigation
          v-if="page?.entry?.id !== 'home' && page?.entry?.slug !== 'home'"
        />
        <Component
          :is="`blueprint-${page?.entry?.blueprint?.replace('_', '-')}`"
          :page="page?.entry"
        />
      </div>
      <Footer v-if="page?.entry?.id !== 'home'" />
    </template>
  </div>
</template>

<script setup>
import { store } from '/store.js'

const route = useRoute()
const { locale, locales } = useI18n()
const entry = ref(null)

const localeRegex = new RegExp(
  `/(${locales.value.map((l) => l.code).join('|')})(?=/|$)`
)

let uri = route.path.replace(localeRegex, '')
uri = uri === '' ? '/' : uri

const { data: page } = await useAsyncGql({
  operation: 'Entry',
  variables: {
    uri: uri,
    site: locale,
  },
})

entry.value = page?.value?.entry

if (!entry.value) {
  throw createError({
    fatal: true,
    statusCode: 404,
  })
}

store.pageId = entry.value?.id
store.pageSlug = entry.value?.slug

const usestencilImageUrl = generateUsestencilImageUrl(
  entry.value,
  store,
  locale.value
)

let title = entry.value?.seo?.title || entry.value?.title

// if job add pensum to title
if (entry.value.blueprint === 'jobs' && entry.value.pensum) {
  title = `${entry.value.title} ${entry.value.pensum}`
}
title = formatText(title)

// META TAGS
await useHead({
  htmlAttrs: { lang: locale },
  title: title,
  meta: generateMeta(entry.value.seo, title, usestencilImageUrl),
  link: generateLinks(entry.value.seo),
})
</script>
